(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    set_flash_notice_alert();
    init_filter_panel();
    init_modal_reset();
  }

//---------------------------------------------------------------------------------------------------
// FLASH NOTICE ALERT
//---------------------------------------------------------------------------------------------------

  const set_flash_notice_alert = function() {
    const $alert = $('#flash_notice_alert');
    if ($alert.length) {
      setTimeout( function() { $alert.slideUp('slow'); }, 2000);
    }
  }

//---------------------------------------------------------------------------------------------------
// FILTER PANEL
//---------------------------------------------------------------------------------------------------

  const init_filter_panel = function() {
    if ($('div#filter_panel').length) {
      $('div#filter_panel > div:first').click(function() {
        if ($('div#filter_panel > div.expanded:first').length) {
          $('div#filter_panel > div#expandable').slideUp('fast', function() {
            $('div#filter_panel > div:first').removeClass('expanded');
          });
        } else {
          $('div#filter_panel > div#expandable').slideDown('fast', function() {
            $('div#filter_panel > div:first').addClass('expanded');
          });
        }
      });
    }
  }

//---------------------------------------------------------------------------------------------------
// INITIALIZE MODAL AFTER CLOSED
//---------------------------------------------------------------------------------------------------

  // reset modal
  const init_modal_reset = function() {
    const $modal = $('#modal');
    if ($modal.length) {
      $modal.on('hidden.bs.modal', function(e) {
        const $modal_dialog = $(this).find('.modal-dialog'),
              $modal_header = $(this).find('.modal-header'),
              $modal_footer = $(this).find('.modal-footer');

        // content reset
        if ($modal_header.is(':hidden')) $modal_header.show();
        if ($modal_footer.is(':hidden')) $modal_footer.show();
        $('#modal .modal-body').html('&nbsp;');
        $('#modal .modal-header .modal-title').html('&nbsp;');

        // size reset
        if ($modal_dialog.hasClass('modal-sm')) $modal_dialog.removeClass('modal-sm');
        if ($modal_dialog.hasClass('modal-lg')) $modal_dialog.removeClass('modal-lg');
        if ( ! $modal_dialog.hasClass('modal-xl')) $modal_dialog.addClass('modal-xl');

        // data reset
        $modal.data('bs.modal', null);
      })
    }
  }

})(jQuery);

//---------------------------------------------------------------------------------------------------
// CURRENCY FORMAT
//---------------------------------------------------------------------------------------------------

Number.prototype.number_to_currency = function(n, x, s, c) {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = this.toFixed(Math.max(0, ~~n));
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

//---------------------------------------------------------------------------------------------------
// MODAL SPINNER
//---------------------------------------------------------------------------------------------------

// const modal_spinner_html = '<div class="text-center pt-5 pb-5"><i class="fad fa-sync-alt fa-spin fa-5x"></i></div>';
const modal_spinner_html = '<div class="text-center pt-5 pb-5"><div class="timer-loader">Loading…</div></div>';
