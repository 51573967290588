(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    if ($('#frontend-home-index').length) {
      init_video_list();
      init_back_to_top_btn();
      init_video_modal_handler();
    }
  }

  const init_video_list = function() {
    $('#video_list .card .card-body .video-title').each(function() {
      $clamp($(this)[0], {clamp: 2});
    });
    $('#video_list .card .card-body .channel-title').each(function() {
      $clamp($(this)[0], {clamp: 1});
    });
  }

  const init_back_to_top_btn = function() {
    var $btn = $('#back_to_top_btn');

    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        $btn.addClass('show');
      } else {
        $btn.removeClass('show');
      }
    });

    $btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
      $btn[0].blur();
    });
  }

  let player;
  let playback_rate = 1;

  const init_video_modal_handler = function() {
    $('.video-modal').click(function() {

      const create_player = function(video_id) {
        player = new YT.Player('player', {
          videoId: video_id,
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
      }

      const onPlayerReady = function(event) {
        event.target.playVideo();
        player.setPlaybackRate(playback_rate);
        reset_playback_rate_inds();
      }

      const onPlayerStateChange = function(event) {
      }

      const stopVideo = function() {
      }

      create_player($(this).data('youtube_id'));
      $('#video_modal').modal('show');
    });

    const reset_playback_rate_inds = function() {
      $('#video_modal .modal-body #playback_rate_inds :visible').hide();
      const $ind = $('#playback_rate_inds [data-playback-rate="' + playback_rate + '"]');
      if ($ind.length) $ind.show();
    }

    $('#video_modal .modal-body #playback_rate_down').click(function() {
      const $prud = $('#video_modal .modal-body #playback_rate_up:disabled');
      if ($prud.length) $prud.prop('disabled', false);

      const $prev = $('#video_modal .modal-body #playback_rate_inds [data-playback-rate="' + playback_rate + '"]').prev();
      if ($prev.length) {
        playback_rate = $prev.data('playback-rate');
        player.setPlaybackRate(playback_rate);
        reset_playback_rate_inds();

        if ( ! $prev.prev().length) {
          $(this).prop('disabled', true);
        }
      }
    });

    $('#video_modal .modal-body #playback_rate_up').click(function() {
      const $prdd = $('#video_modal .modal-body #playback_rate_down:disabled');
      if ($prdd.length) $prdd.prop('disabled', false);

      const $next = $('#video_modal .modal-body #playback_rate_inds [data-playback-rate="' + playback_rate + '"]').next();
      if ($next.length) {
        playback_rate = $next.data('playback-rate');
        player.setPlaybackRate(playback_rate);
        reset_playback_rate_inds();

        if ( ! $next.next().length) {
          $(this).prop('disabled', true);
        }
      }
    });

    $('#video_modal').on('hidden.bs.modal', function(e) {
      player = null;
      $('#video_modal .modal-body #player').replaceWith('<div id="player" class="embed-responsive-item"></div>');

      // data reset
      $(this).data('bs.modal', null);
    })
  }

})(jQuery);
